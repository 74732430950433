.container {
  width: 1200px;
}
.card {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  width: 100%;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.productImg {
  align-items: center;
  display: flex;
  height: 275px;
  justify-content: center;
}
.productCard .card:hover {
  background: #f1f1f1;
}

h5.product-name {
  font-size: 15px;
  display: flex;
  align-items: center;
}

.productCard a {
  text-decoration: none;
}

.image-container {
  position: relative;
}

.thumbnail-image {
  width: 90%;
}

.discount {
  background-color: #292929;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  border-radius: 6px;
  color: #fff;
}

.wishlist {
  height: 25px;
  width: 25px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.first {
  position: absolute;
  width: 100%;
  padding: 9px;
}

.dress-name {
  font-size: 18px;
  font-weight: 500;
  width: 75%;
}

.new-price {
  font-size: 20px;
  font-weight: bold;
  color: #ff6a00;
}
.old-price {
  font-size: 17px;
  font-weight: bold;
  color: grey;
  text-decoration-line: line-through;
  text-align: left;
}

.productCard {
  padding-bottom: 80px;
}
.productCard .btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 3px;
  margin-left: 2px;
}

.creme {
  background-color: #fff;
  border: 2px solid grey;
}
.creme:hover {
  border: 3px solid grey;
}

.creme:focus {
  background-color: grey;
}

.red {
  background-color: #fff;
  border: 2px solid red;
}

.red:hover {
  border: 3px solid red;
}
.red:focus {
  background-color: red;
}

.blue {
  background-color: #fff;
  border: 2px solid #40c4ff;
}

.blue:hover {
  border: 3px solid #40c4ff;
}
.blue:focus {
  background-color: #40c4ff;
}
.darkblue {
  background-color: #fff;
  border: 2px solid #01579b;
}
.darkblue:hover {
  border: 3px solid #01579b;
}
.darkblue:focus {
  background-color: #01579b;
}
.yellow {
  background-color: #fff;
  border: 2px solid #ffca28;
}
.yellow:hover {
  border-radius: 3px solid #ffca28;
}
.yellow:focus {
  background-color: #ffca28;
}

.item-size {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid grey;
  color: grey;
  font-size: 10px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.rating-star {
  font-size: 16px !important;
}
.rating-number {
  font-size: 16px;
  color: grey;
  margin-right: 5px;
}
.buy {
  font-size: 18px;
  color: black;
  font-weight: 500;
  border: 1px solid #d6d6d6;
  padding: 0px 15px;
  background: #f9af23;
  border-radius: 5px;
}

.prices {
  display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
}

.voutchers {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
}
.voutcher-divider {
  display: flex;
}
.voutcher-left {
  width: 60%;
}
.voutcher-name {
  color: grey;
  font-size: 16px;
  font-weight: 500;
}
.voutcher-code {
  color: red;
  font-size: 11px;
  font-weight: bold;
}
.voutcher-right {
  width: 40%;
  background-color: #f58314;
  color: #fff;
}

.discount-percent {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: 5px;
}
.off {
  font-size: 14px;
  position: relative;
  bottom: 5px;
}
.productGridMobile {
  grid-row-gap: 20px;
}

@media (max-width: 768px) {
  .productGridMobile {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }

  h5.product-name {
    font-size: 14px;
    height: 50px;
  }
  .new-price {
    font-size: 16px;
  }
  .old-price {
    font-size: 14px;
  }
  .thumbnail-image {
    padding: 5px;
    width: 100%;
  }
  .productImg {
    height: 160px;
  }
  .buy {

    padding: 0px 5px;

  }
}
