footer {
    background: #202020;
}

ul {
    list-style: none;
    padding: auto;
}

ul i.bi {
    margin-left: 10px;
}

.iconSocial i {
    font-size: 35px;
    margin-left: 10px;
    color: #f9af23;
}

table {
    width: 100%;
}
#formControlLg {
    border: 0;
    padding: 3px 0px;
}
.btnsrc {
    background-color: #f9af23;
    border: 0;
    color: white;
    padding: 3px 8px;
}