@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css');

.infoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bi.header {
  font-size: 32px;
  margin-left: 5px;
}
.mainHeader {
  display: grid;
  grid-template-columns: 1.2fr 1.6fr 0.6fr 0.6fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  align-items: center;
  justify-items: center;
}

.cartItems {
  display: inline-block;
  position: relative;
  right: 35px;
  bottom: 17px;
  background: #f9af23;
  color: white;
  padding: 2px;
  font-size: 11px;
  width: 15px;
  border-radius: 100%;
  height: 15px;
  text-align: center;
  line-height: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cartItems.wishlist {
  background: #e91e63;
}

.storeHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
}

.infoHeader {
  background-color: #f8f8f8;
}
.infoHeader ul,
.storeHeader ul {
  display: flex;
  margin: 0;
  padding: 5px 10px;
}
.infoHeader li,
.storeHeader li {
  list-style: none;
  padding: 0px 5px;
}

.infoHeader a {
  color: black;
  text-decoration: none;
}
.infoHeader a:hover {
  color: rgb(241, 131, 57);
  font-weight: 500;
}
.mainHeader a {
  color: black;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}
.mainHeader a:hover {
  color: rgb(241, 131, 57);
  font-weight: 500;
}
.storeHeader a {
  color: black;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}
.storeHeader a:hover {
  color: rgb(241, 131, 57);
  font-weight: 700;
}
.mainHeader {
  padding: 10px;
}
.mainHeader .logo img {
  max-width: 180px;
}
.searchBlock,
.searchBlock input {
  width: 100%;
}

@media (max-width: 768px) {
  .mainHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      'logo logo logo logo'
      'wishlistmenu wishlistmenu cartmenu cartmenu';
    padding: 0px;
  }
  .logo {
    grid-area: logo;
  }
  .wishlistmenuM {
    grid-area: wishlistmenu;
  }
  .cartmenuM {
    grid-area: cartmenu;
  }

  .searchBlock {
    display: none;
  }
  .scrollmenuM {
    overflow-x: scroll;
  }
  .storeHeader ul {
    width: max-content;
  }
  .storeHeader {
    position: sticky;
    top: 5px;
  }
  .infoHeader {
    font-size: 12px;
  }
  .storeHeader a {
    font-size: 14px;
  }
  .scrollmenuM i {
    font-size: 20px;
  }
}
