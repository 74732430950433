@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap');

body {
  font-family: 'Rubik', sans-serif;
  direction: rtl;
}

main {
  margin: 0px;
}

.registerBlock {
  max-width: 600px;
  margin: 40px auto;
  border-radius: 10px;
  padding: 20px;
}

.form-group.registerFormGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.carouselBlock {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 40px;
}

.carouselBlock img {
  height: 550px;
}

.aboutus {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

.contactUs {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 0px;
}

.contactUs .formcontact {
  justify-content: center;
}

.loading {
  text-align: center;
  font-size: 22px;
  padding: 40px;
}

.loadingGif {
  max-width: 120px;
}

.category_back {
  margin-bottom: 35px;
  width: 100%;
  height: 400px;
  background-size: cover;
  display: flex;
  align-items: center;
  padding-right: 10%;
}

.category_back h1 {
  font-size: 58px;
  color: white;
  box-shadow: 5px 5px 0px 0px;
  padding: 5px 10px;
}

.paymentForm {
  width: 350px;
  margin: 25px auto;
}

.rccs__number {
  direction: ltr;
}

.newsletter {
  padding: 40px 0;
  background-image: linear-gradient(to right, #e5c78c, #f9af23);
}

.newsletter .content {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

.newsletter .content h2 {
  color: black;
}

.newsletter .content .form-control {
  height: 50px;
  border-color: #ffffff;
  border-radius: 0;
}

.newsletter .content.form-control:focus {
  box-shadow: none;
  border: 2px solid black;
}

.newsletter .content .btn {
  min-height: 50px;
  border-radius: 0;
  background: black;
  color: #fff;
  font-weight: 600;
}

@media (max-width: 768px) {
  main {
    margin: 0px;
  }

  .carouselBlock {
    height: 220px;
  }

  .carouselBlock img {
    height: 200px;
  }

  .carouselimg {
    height: 210px;
  }

  .aboutus {
    padding: 40px 20px;
  }

  .contactUs {
    padding: 40px 20px;
  }

  .categorypage h1 {
    padding: 40px 10px 0px 10px;
  }

  .category_back {
    height: 200px;


    padding-right: 5%;
  }

  .category_back h1 {
    font-size: 28px !important;
  }
}