
.productPage  {
  border: none !important;
  box-shadow: none;
  overflow: hidden;
}
h1.title_ProductName {
  font-size: calc(1.3rem + .6vw);
}

.product_info {
  padding-top: 40px;
}
.thumbnail_images ul {
  list-style: none;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.thumbnail_images ul li {
  margin: 5px;
  padding: 10px;
  border: 2px solid #eee;
  cursor: pointer;
  transition: all 0.5s;
}
.thumbnail_images ul li:hover {
  border: 2px solid #000;
}
.main_image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eee;
  height: 330px;
  width: 100%;
  overflow: hidden;
}
.heart {
  height: 29px;
  width: 29px;
  background-color: #eaeaea;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content p {
  font-size: 12px;
}
.ratings span {
  font-size: 14px;
  margin-left: 12px;
}
.colors {
  margin-top: 5px;
}
.colors ul {
  list-style: none;
  display: flex;
  padding-left: 0px;
}
.colors ul li {
  height: 20px;
  width: 20px;
  display: flex;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}
.colors ul li:nth-child(1) {
  background-color: #6c704d;
}
.colors ul li:nth-child(2) {
  background-color: #96918b;
}
.colors ul li:nth-child(3) {
  background-color: #68778e;
}
.colors ul li:nth-child(4) {
  background-color: #263f55;
}
.colors ul li:nth-child(5) {
  background-color: black;
}
.right-side {
  position: relative;
}
.search-option {
  display: none;
  position: absolute;
  background-color: #000;
  overflow: hidden;
  align-items: center;
  color: #fff;
  width: 200px;
  height: 200px;
  border-radius: 49% 51% 50% 50% / 68% 69% 31% 32%;
  left: 30%;
  bottom: -250px;
  transition: all 0.5s;
  cursor: pointer;
}
.search-option .first-search {
  position: absolute;
  top: 20px;
  left: 90px;
  font-size: 20px;
  opacity: 1000;
}
.search-option .inputs {
  opacity: 0;
  transition: all 0.5s ease;
  transition-delay: 0.5s;
  position: relative;
}
.search-option .inputs input {
  position: absolute;
  top: 200px;
  left: 30px;
  padding-left: 20px;
  background-color: transparent;
  width: 300px;
  border: none;
  color: #fff;
  border-bottom: 1px solid #eee;
  transition: all 0.5s;
  z-index: 10;
}
.search-option .inputs input:focus {
  box-shadow: none;
  outline: none;
  z-index: 10;
}
.search-option:hover {
  border-radius: 0px;
  width: 100%;
  left: 0px;
}
.search-option:hover .inputs {
  opacity: 1;
}
.search-option:hover .first-search {
  left: 27px;
  top: 25px;
  font-size: 15px;
}
.search-option:hover .inputs input {
  top: 20px;
}
.search-option .share {
  position: absolute;
  right: 20px;
  top: 22px;
}
.buttons .btn {
  height: 50px;
  width: 150px;
  border-radius: 0px !important;
}

.pricesPage {
  display: flex;
  justify-content: space-evenly;
  width: 330px;
  align-items: center;
  margin-bottom: 20px;
}

.pricesPage h4 {
  margin: 0;
}

.reviewsBlock {
  margin-left: 10px;
  margin-top: -5px;
}

.btnSubmit {
  margin-top: 10px;
}

.btnSubmit a{
  width: 100%;
}

button.heart {
  border: 0;
}

.heart.check {
  color: #e91e63;
  pointer-events: none;
}

.ullehem {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.list-group-lehem {
  display: inline-block;
    margin: 5px;
}


@media (max-width: 768px) {
  .productPage .buttons {
    justify-content: space-between;
  }
}