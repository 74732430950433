.cartPage {
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
}
.checkoutPage {
  max-width: 460px;
  margin: 40px auto;
  padding: 20px;
}
.checkoutPage ul {
  list-style: auto;
}
.cartPage .summary {
  display: grid;
  margin: 40px auto;
  background: whitesmoke;
  padding: 20px;
  border-radius: 15px;
}
.icredittext {
  font-size: small;
}

.cartPage p {
  margin-bottom: 5px;
}

.cartPage .quantity {
  width: 20px;
  text-align: center;
  line-height: 30px;
}

.popup {
  z-index: 9;
  position: fixed;
  background: #000000a3;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  padding: 10%;
  text-align: center;
}
.popup .popup-content {
  background: white;
  padding: 20px;
  border-radius: 15px;
  width: 350px;
  margin: 0 auto;
  display: grid;
  gap: 5px;
}
.disabledBtn {
  pointer-events: none;
}
@media (min-width: 768px) {
  .imgitem {
    max-width: 55% !important;
  }
}
@media (max-width: 768px) {
  .popup .popup-content {
    width: auto;
  }
  .productName {
    font-size: 11px;
  }
}
